<template>
  <el-date-picker
    size="mini"
    format="yyyy/MM/dd"
    value-format="yyyy-MM-dd"
    v-model="dateRange"
    type="daterange"
    range-separator="~"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :picker-options="pickerOptions"
    :clearable="false"
    prefix-icon="el-icon-time"
  >
  </el-date-picker>
</template>

<script>
import { adPickerOptions, fnCSTToYmd } from "@/utils/utils";
export default {
  props: {
    initDateRange: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dateRange: [],
      pickerOptions: adPickerOptions,
      initPageDate: []
    };
  },
  computed: {
    defaultValue() {
      let end = new Date(),
        start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * (24 * (30 - 1)));
      return [fnCSTToYmd(start, { tag: 1 }), fnCSTToYmd(end, { tag: 1 })];
    }
  },
  methods: {},
  mounted() {
    if (this.initDateRange && this.initDateRange.length) {
      this.dateRange = [...this.initDateRange];
    } else {
      this.dateRange = this.defaultValue;
    }
  },
  watch: {
    dateRange: {
      handler(val) {
        if (val && val.length) {
          // console.log("1-watch-dateRange")
          this.$emit("change-date", val);
        }
      },
      deep: true
    }
  }
};
</script>

<style>
</style>